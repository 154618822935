<div class="logo-container">
    <img src="../../assets/logo.svg" alt="">

    <div class="style-toggle-container">
        <div class="icon">
            <i-sun></i-sun>
        </div>
        <div>
            <mat-slide-toggle (click)="toggleTheme()">
            </mat-slide-toggle>
        </div>
        <div class="icon">
            <i-moon></i-moon>
        </div>
    </div>
</div>

<div class="controls-container">
    <button type="button" (click)="openTripList()">
        <i-list class="icon"></i-list>
    </button>
    <button type="button" (click)="openTripChart()">
        <i-bar-chart-2 class="icon"></i-bar-chart-2>
    </button>
    <button type="button" (click)="openAbout()">
        <i-users class="icon"></i-users>
    </button>
</div>

<div class="map-container">
    <agm-map [latitude]="mapDetails.latitude" [longitude]="mapDetails.longitude" [zoom]="mapDetails.zoom" [styles]="stylesJson">
        <agm-marker *ngFor="let marker of orderedMarkers" [latitude]="marker.latitude" [longitude]="marker.longitude"></agm-marker>
    </agm-map>
</div>