<div class="list-container">
    <h2 mat-dialog-title>Trip List</h2>
    <div class="trip-list-container">
        <table mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="place">
                <th mat-header-cell *matHeaderCellDef [ngClass]="place"> Place </th>
                <td mat-cell *matCellDef="let element"> {{element.displayTitle}} </td>
            </ng-container>

            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef [ngClass]="date"> Date </th>
                <td mat-cell *matCellDef="let element"> {{element.year}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</div>